import React from "react";
import Layout from "../components/Layout/Layout";
import Header from "../components/Header/Header";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const NotFoundPage = ({ data: { site, background1 } }) => {
  return (
    <Layout siteMetadata={site.siteMetadata}>
      <div className="landing-bg-container">
        <GatsbyImage className="screen" image={getImage(background1)} alt="S kapsami" />
      </div>
      <div className="screen screen-lead">
        <div className="screen-content">
          <Header />
          <span className="flex-grow"></span>
        </div>
        <div className="options">
          <h2>Stránka nenalezena</h2>
        </div>
      </div>
    </Layout>
  )
};

export default NotFoundPage;

export const pageQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        author
        description
        siteUrl
        title
        keywords
      }
    }
    background1: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "background-1.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          blurredOptions: {
            width: 160
          }
          breakpoints: [350, 500, 600]
        )
      }
    }
  }
`;
